import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.webp";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { CiMenuFries } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { BsArrowBarUp } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";

const Navbar = () => {
  const [menu, setMenu] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Prevent body scroll when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = (item) => {
    setMenu(item);
    setIsMenuOpen(false);
  };

  const ConnectButton = () => (
    <button
      className="bg-gradient-to-r from-[#da7c25] to-[#b515ffba] 
      text-white text-[22px] px-[30px] py-[15px] rounded-[50px] 
      hover:scale-105 transition-transform duration-500 border-0"
    >
      <AnchorLink
        href="#contact"
        offset={50}
        className="text-white no-underline"
      >
        Connect With Me
      </AnchorLink>
    </button>
  );

  return (
    <nav className="w-full px-4 md:px-6 lg:px-8 py-4 max-w-full overflow-hidden relative">
      <div className="max-w-[1600px] mx-auto flex items-center justify-between">
        {/* Logo */}
        <img
          src={logo}
          alt="Logo"
          className="w-[80px] h-[80px] sm:w-[100px] sm:h-[100px] md:w-[120px] md:h-[120px] 
          rounded-full object-cover"
        />

        {/* Desktop Menu */}
        <ul className="hidden md:flex items-center space-x-8">
          {["home", "about", "services", "work", "gallery", "contact"].map(
            (item) => (
              <li key={item} className="relative">
                <AnchorLink
                  href={`#${item}`}
                  offset={item !== "home" ? 50 : 0}
                  className="text-white no-underline hover:text-gray-300 text-lg"
                  onClick={() => setMenu(item)}
                >
                  <div className="flex flex-col items-center">
                    <span>
                      {item === "about"
                        ? "About Me"
                        : item.charAt(0).toUpperCase() + item.slice(1)}
                    </span>
                    {menu === item && (
                      <BsArrowBarUp className="mt-2 text-2xl" />
                    )}
                  </div>
                </AnchorLink>
              </li>
            )
          )}
        </ul>

        {/* Right Side Items Container */}
        <div className="flex items-center gap-6">
          {/* Connect Button - visible only on desktop */}
          <div className="hidden md:block">
            <ConnectButton />
          </div>

          {/* Instagram Icon */}
          <div className="flex items-center z-50">
            <a
              href="https://www.instagram.com/the.solitary.photographer/"
              target="_blank"
              rel="noreferrer"
              className="text-[32px] sm:text-[36px] md:text-[40px] text-white no-underline hover:text-gray-300 flex items-center translate-y-[2px]"
              onClick={(e) => e.stopPropagation()}
            >
              <GrInstagram />
            </a>
          </div>

          {/* Mobile Menu Button */}
          <button onClick={toggleMenu} className="md:hidden text-3xl z-50">
            {isMenuOpen ? <IoMdClose /> : <CiMenuFries />}
          </button>
        </div>

        {/* Overlay */}
        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black/50 z-30 md:hidden"
            onClick={() => setIsMenuOpen(false)}
          />
        )}

        {/* Mobile Menu Dropdown */}
        <div
          className={`fixed top-[88px] left-0 w-full bg-[#2D1F3F] border-t border-gray-700
          md:hidden transition-all duration-300 ease-in-out z-40
          ${
            isMenuOpen
              ? "opacity-100 translate-y-0"
              : "opacity-0 -translate-y-full"
          }`}
        >
          <ul className="flex flex-col items-center py-6 space-y-4">
            {["home", "about", "services", "work", "gallery", "contact"].map(
              (item) => (
                <li key={item} className="relative w-full text-center">
                  <AnchorLink
                    href={`#${item}`}
                    offset={item !== "home" ? 50 : 0}
                    className="text-white no-underline hover:text-gray-300 text-lg block py-2"
                    onClick={() => handleMenuClick(item)}
                  >
                    <div className="flex flex-col items-center">
                      <span>
                        {item === "about"
                          ? "About Me"
                          : item.charAt(0).toUpperCase() + item.slice(1)}
                      </span>
                      {menu === item && (
                        <BsArrowBarUp className="mt-2 text-2xl" />
                      )}
                    </div>
                  </AnchorLink>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
