import landscape1 from "./landscape1.webp";
import landscape2 from "./landscape2.webp";
import landscape3 from "./landscape3.webp";
import landscape4 from "./landscape4.webp";
import landscape5 from "./landscape5.webp";
import landscape6 from "./landscape6.webp";

const landscapephotos_data = [
  {
    w_no: 7,
    w_name: "Gallery",
    w_img: landscape1,
  },
  {
    w_no: 8,
    w_name: "Gallery",
    w_img: landscape2,
  },
  {
    w_no: 9,
    w_name: "Gallery",
    w_img: landscape3,
  },
  {
    w_no: 10,
    w_name: "Gallery",
    w_img: landscape4,
  },
  {
    w_no: 11,
    w_name: "Gallery",
    w_img: landscape5,
  },
  {
    w_no: 12,
    w_name: "Gallery",
    w_img: landscape6,
  },
];

export default landscapephotos_data;
