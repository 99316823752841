import React, { useState, useEffect } from "react";
import "./Footer.css";
import footer_logo from "../../assets/logo.webp";
import Modal from "../Modal/Modal";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleVisibility = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop > 300 && scrollTop + windowHeight < documentHeight - 50) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="footer">
      <div className="footer-top">
        <div className="footer-top-left">
          <img src={footer_logo} alt="" />
        </div>
        <div className="footer-top-right">
          <p>
            <span>Capturing Moments, Creating Lasting Memories. </span>
            Dedicated to telling stories through stunning imagery. Specialising
            in landscape, event, and action photography, I strive to capture the
            beauty and energy of every moment. With a passion for detail and a
            creative eye, my goal is to transform experiences into lasting
            memories. Let's connect and create something unforgettable together.
          </p>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <p className="footer-bottom-left">© 2024 James. All rights reserved</p>
        <hr></hr>
        <p>Website developed by Pippa B. Contact at: pipbar123@gmail.com</p>
        <hr></hr>
        <div className="footer-bottom-right">
          <p onClick={() => openModal("terms")} style={{ cursor: "pointer" }}>
            Terms of Service
          </p>
          <p onClick={() => openModal("privacy")} style={{ cursor: "pointer" }}>
            Privacy Policy
          </p>
          <p onClick={() => openModal("cookies")} style={{ cursor: "pointer" }}>
            Cookies Policy
          </p>
        </div>
      </div>

      {isModalOpen && <Modal type={modalType} onClose={closeModal} />}

      {isVisible && (
        <button className="back-to-top-btn" onClick={scrollToTop}>
          Back to Top
        </button>
      )}
    </div>
  );
};

export default Footer;
