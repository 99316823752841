import myWork1 from "./myWork1.webp";
import myWork2 from "./myWork2.webp";
import myWork3 from "./myWork3.webp";
import myWork4 from "./myWork4.webp";
import myWork5 from "./myWork5.webp";
import myWork6 from "./myWork6.webp";
import myWork7 from "./myWork7.webp";
import myWork8 from "./myWork8.webp";
import myWork9 from "./myWork9.webp";

const mywork_data = [
  {
    w_no: 1,
    w_name: "Web design",
    w_img: myWork1,
  },
  {
    w_no: 2,
    w_name: "Web design",
    w_img: myWork2,
  },
  {
    w_no: 3,
    w_name: "Web design",
    w_img: myWork3,
  },
  {
    w_no: 4,
    w_name: "Web design",
    w_img: myWork4,
  },
  {
    w_no: 5,
    w_name: "Web design",
    w_img: myWork5,
  },
  {
    w_no: 6,
    w_name: "Web design",
    w_img: myWork6,
  },
  {
    w_no: 7,
    w_name: "Web design",
    w_img: myWork7,
  },
  {
    w_no: 8,
    w_name: "Web design",
    w_img: myWork8,
  },
  {
    w_no: 9,
    w_name: "Web design",
    w_img: myWork9,
  },
];

export default mywork_data;

// const mywork_data = [
//     {
//         w_no:1,
//         w_name:"Web design",
//         w_img:project1_img
//     },
//     {
//         w_no:2,
//         w_name:"Web design",
//         w_img:project2_img
//     },
//     {
//         w_no:3,
//         w_name:"Web design",
//         w_img:project3_img
//     },
//     {
//         w_no:4,
//         w_name:"Web design",
//         w_img:project4_img
//     },
//     {
//         w_no:5,
//         w_name:"Web design",
//         w_img:project5_img
//     },
//     {
//         w_no:6,
//         w_name:"Web design",
//         w_img:project6_img
//     },
// ]

// export default mywork_data;
