import event1 from "./event1.webp";
import event2 from "./event2.webp";
import event3 from "./event3.webp";
import event4 from "./event4.webp";
import event5 from "./event5.webp";
import event6 from "./event6.webp";

const eventphotos_data = [
  {
    w_no: 13,
    w_name: "Gallery",
    w_img: event1,
  },
  {
    w_no: 14,
    w_name: "Gallery",
    w_img: event2,
  },
  {
    w_no: 15,
    w_name: "Gallery",
    w_img: event3,
  },
  {
    w_no: 16,
    w_name: "Gallery",
    w_img: event4,
  },
  {
    w_no: 17,
    w_name: "Gallery",
    w_img: event5,
  },
  {
    w_no: 18,
    w_name: "Gallery",
    w_img: event6,
  },
];

export default eventphotos_data;
