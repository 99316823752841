import action1 from "./action1.webp";
import action2 from "./action2.webp";
import action3 from "./action3.webp";
import action4 from "./action4.webp";
import action5 from "./action5.webp";
import action6 from "./action6.webp";

const actionphotos_data = [
  {
    w_no: 1,
    w_name: "Gallery",
    w_img: action1,
  },
  {
    w_no: 2,
    w_name: "Gallery",
    w_img: action2,
  },
  {
    w_no: 3,
    w_name: "Gallery",
    w_img: action3,
  },
  {
    w_no: 4,
    w_name: "Gallery",
    w_img: action4,
  },
  {
    w_no: 5,
    w_name: "Gallery",
    w_img: action5,
  },
  {
    w_no: 6,
    w_name: "Gallery",
    w_img: action6,
  },
];

export default actionphotos_data;
